import React from 'react'
import Mountain from '../images/yetiMountain.png';

const Footer = () => {
    return (
        <img className='mtns' src={Mountain} alt='Valhalla Mountains'></img>
    )
}

export default Footer
